import _deflate from "./lib/deflate";
import _inflate from "./lib/inflate";
import _constants from "./lib/zlib/constants";
var exports = {};
const {
  Deflate,
  deflate,
  deflateRaw,
  gzip
} = _deflate;
const {
  Inflate,
  inflate,
  inflateRaw,
  ungzip
} = _inflate;
const constants = _constants;
exports = {
  Deflate,
  deflate,
  deflateRaw,
  gzip,
  Inflate,
  inflate,
  inflateRaw,
  ungzip,
  constants
};
export default exports;
const _Deflate = exports.Deflate,
      _deflate2 = exports.deflate,
      _deflateRaw = exports.deflateRaw,
      _gzip = exports.gzip,
      _Inflate = exports.Inflate,
      _inflate2 = exports.inflate,
      _inflateRaw = exports.inflateRaw,
      _ungzip = exports.ungzip,
      _constants2 = exports.constants;
export { _Deflate as Deflate, _deflate2 as deflate, _deflateRaw as deflateRaw, _gzip as gzip, _Inflate as Inflate, _inflate2 as inflate, _inflateRaw as inflateRaw, _ungzip as ungzip, _constants2 as constants };